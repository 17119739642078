const MAJOR_ROOTS = ['C','C#','Db','D','Eb','E','F','F#','Gb','G','Ab','A','Bb','B'];
const MINOR_ROOTS = ['A','A#','Bb','B','C','C#','D','D#','Eb','E','F','F#','G','G#'];
const MAJORS = {
  // theoretical scales points to the practical root. Eg, Cb major points to 'B'
  'Cb' : 'B',
  'C'  : [ 'C','D','E','F','G','A','B' ],
  'C#' : [ 'C#','D#','E#','F#','G#','A#','B#' ],

  'Db' : [ 'Db','Eb','F','Gb','Ab','Bb','C' ],
  'D'  : [ 'D','E','F#','G','A','B','C#' ],
  'D#' : 'Eb',

  'Eb' : [ 'Eb','F','G','Ab','Bb','C','D' ],
  'E'  : [ 'E','F#','G#','A','B','C#','D#' ],
  'E#' : 'F',

  'Fb' : 'E',
  'F'  : [ 'F','G','A','Bb','C','D','E' ],
  'F#' : [ 'F#','G#','A#','B','C#','D#','E#' ],

  'Gb' : [ 'Gb','Ab','Bb','Cb','Db','Eb','F' ],
  'G'  : [ 'G','A','B','C','D','E','F#' ],
  'G#' : 'Ab',

  'Ab' : [ 'Ab','Bb','C','Db','Eb','F','G' ],
  'A'  : [ 'A','B','C#','D','E','F#','G#' ],
  'A#' : 'Bb',

  'Bb' : [ 'Bb','C','D','Eb','F','G','A' ],
  'B'  : [ 'B','C#','D#','E','F#','G#','A#' ],
  'B#' : 'C',
};
const MINOR_REMAP = {
  'Ab' : 'G#',
  'B#' : 'C',
  'Cb' : 'B',
  'Db' : 'C#',
  'E#' : 'F',
  'Fb' : 'E',
  'Gb' : 'F#',
};

export class ScaleLookup {
  static get MAJOR_ROOTS() { return MAJOR_ROOTS; }
  static get MINOR_ROOTS() { return MINOR_ROOTS; }
  static get MAJORS() { return MAJORS; }
  static get MINOR_REMAP() { return MINOR_REMAP; }
}